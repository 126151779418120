import React, {useState, useEffect, useContext} from 'react';
import {useOutletContext} from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {differenceWith, isEqual} from 'lodash';
import WatchlistTable from './WatchlistTable';
import 'react-datepicker/dist/react-datepicker.css';
import AppContext from '../../Context/AppContext';

dayjs.extend(utc);
dayjs.extend(timezone);

const AddToWatchlist = ({watchlistData, handleAddToWatchlist}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [inputSuccess, setInputSuccess] = useState(false);
  const [inputSuccessMessage, setInputSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    setInputValue(e.target.value);
    setInputError('');
    setInputSuccessMessage('');
    setInputSuccess(false);
  };

  const isString = (value) => typeof value === 'string' || value instanceof String;

  const existsInWatchlist = (symbol) => {
    if (!watchlistData || !watchlistData?.data?.length) {
      return false;
    }
    const found = watchlistData?.data?.find(
      (item) =>
        isString(item?.Symbol?.value) &&
        isString(symbol) &&
        item?.Symbol?.value?.toLowerCase() === symbol.toLowerCase(),
    );
    return found;
  };

  const updateWatchlist = async (e) => {
    e.preventDefault();

    if (!inputValue) {
      setInputError('Please enter a ticker symbol.');
      return;
    }

    if (!isString(inputValue)) {
      setInputError('Please enter a valid ticker symbol.');
      return;
    }

    if (existsInWatchlist(inputValue)) {
      setInputError('Symbol already exists in your watchlist.');
      return;
    }

    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (!token) {
      token = localStorage.getItem('scanner-sso');
    }
    if (!token) {
      setInputError('Please sign in or refresh page to add to your watchlist.');
      return;
    }
    setLoading(true);
    try {
      await handleAddToWatchlist(null, inputValue);
      setInputSuccessMessage(`Successfully added ${inputValue} to your watchlist.`);
      setInputSuccess(true);
      setInputValue('');
    } catch (err) {
      setInputError('There was an error adding the symbol to your watchlist.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputSuccess) {
      setTimeout(() => {
        setInputSuccessMessage('');
        setInputSuccess(false);
      }, 5000);
    }
  }, [inputSuccess]);

  return (
    <div className="pb-5">
      <form className="flex items-center">
        <div className="w-full max-w-xs">
          <label htmlFor="ticker" className="sr-only">
            Ticker
          </label>
          <input
            disabled={loading}
            onChange={onInputChange}
            value={inputValue}
            id="ticker"
            name="ticker"
            type="ticker"
            placeholder="AAPL"
            style={{background: 'rgba(148, 196, 242, 0.1)'}}
            className="block w-full rounded-md border-0 py-1.5 px-1 text-[#70769B] placeholder:text-[#70769b] shadow-sm ring-transparent outline-none focus:ring-transparent text-sm leading-6"
          />
        </div>
        <button
          onClick={updateWatchlist}
          disabled={loading || !inputValue}
          type="submit"
          className="inline-flex items-center justify-center rounded-md text-[#70769B] bg-[#282A39] border-gray-300  px-3 py-2 text-sm font-semibold shadow-sm transition-all hover:bg-[#70769B] hover:text-[#282A39] focus-visible:outline focus-visible:outline-0  ml-3 mt-0 w-auto"
        >
          {loading ? <div className="watchlist-loader" /> : 'Add To Watchlist'}
        </button>
      </form>
      {inputError && <p className="mt-2 text-sm text-red-600">{inputError}</p>}
      <p className={`${inputSuccessMessage ? 'fadeIn' : 'fadeOut'} text-sm text-green-600`}>{inputSuccessMessage}</p>
    </div>
  );
};

const Watchlist = ({optionsColumns, optionsAllowed, lockedColumns, allowCustomInputWatchlist, allowedChart}) => {
  const [state] = useOutletContext();
  const {groupCollection} = state;
  const {activeWatchlist, activeWatchlistSymbols, handleAddToWatchlist} = useContext(AppContext);
  const [watchlistSettings, setWatchlistSettings] = useState({
    page: 0,
    orderBy: 'Symbol',
    order: 'asc',
    rowsPerPage: 100,
  });
  const [watchlistData, setWatchlistData] = useState({
    data: [],
    dataTypes: [],
  });

  const handleChangePage = (event, newPage) => {
    setWatchlistSettings({...watchlistSettings, page: newPage});
  };

  const handleChangeOrderBy = (event, property) => {
    const isAsc = watchlistSettings.orderBy === property && watchlistSettings.order === 'asc';
    setWatchlistSettings({
      ...watchlistSettings,
      orderBy: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setWatchlistSettings({
      ...watchlistSettings,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const highlightRow = (newRowKey, prevValue, newRowVal, symbol) => {
    const animationClassNameIncrease = `${newRowKey}Increase`;
    const animationClassNameDecrease = `${newRowKey}Decrease`;
    const animationAnimationOutClassName = `${newRowKey}AnimationOut`;
    const currentCell = document.getElementById(symbol);
    if (!currentCell || !currentCell?.classList) return;
    currentCell.classList.remove(animationAnimationOutClassName);
    if (prevValue < newRowVal) {
      currentCell.classList.add(animationClassNameIncrease);
    } else if (prevValue > newRowVal) {
      currentCell.classList.add(animationClassNameDecrease);
    }

    setTimeout(() => {
      const cell = document.getElementById(symbol);
      if (!cell) return;
      cell.classList.remove(animationClassNameIncrease, animationClassNameDecrease);
      cell.classList.add(animationAnimationOutClassName);
    }, 1000);
  };

  useEffect(() => {
    if (!activeWatchlist || !groupCollection) {
      return;
    }
    const watchlistKeys = Object.keys(activeWatchlist ?? {});
    const {
      allTabs,
      watchlistDB: allDB,
      useParentGroupDataType,
      parentGroup,
    } = allowCustomInputWatchlist ?? {
      allTabs: [],
      watchlistDB: null,
      useParentGroupDataType: false,
      parentGroup: null,
    };

    if (watchlistKeys?.length) {
      // Get data types for watchlist table
      const wlDataTypes = !useParentGroupDataType
        ? watchlistKeys.reduce((acc, key) => {
            if (groupCollection?.[key] && Object.keys(groupCollection?.[key] ?? {}).length) {
              if (Object.keys(groupCollection[key]?.dataTypes ?? {}).length) {
                const mergedWithAcc = [...(acc ?? []), ...groupCollection[key].dataTypes];
                const arrayUniqueByKey = [...new Map(mergedWithAcc.map((item) => [item.key, item])).values()];
                return arrayUniqueByKey;
              }
              return acc;
            }

            let maybeKey = null;
            if (allTabs?.length && Object.keys(groupCollection ?? {}).find((k) => allTabs.includes(k))) {
              maybeKey = Object.keys(groupCollection ?? {}).find((k) => allTabs.includes(k));
            }

            if (maybeKey && groupCollection?.[maybeKey] && Object.keys(groupCollection?.[maybeKey] ?? {}).length) {
              if (Object.keys(groupCollection[maybeKey]?.dataTypes ?? {}).length) {
                const mergedWithAcc = [...(acc ?? []), ...groupCollection[maybeKey].dataTypes];
                const arrayUniqueByKey = [...new Map(mergedWithAcc.map((item) => [item.key, item])).values()];
                return arrayUniqueByKey;
              }
              return acc;
            }

            return acc;
          }, [])
        : groupCollection?.[parentGroup]?.dataTypes ?? [];

      // Get symbol data for watchlist table
      let wlData = [];
      if (!useParentGroupDataType || !parentGroup) {
        wlData = watchlistKeys.reduce((acc, key) => {
          const symbolArr = activeWatchlist[key];
          if (!groupCollection?.[key] || !Object.keys(groupCollection?.[key] ?? {}).length) {
            if (allDB && Object.keys(groupCollection[allDB]?.data ?? {}).length) {
              const fromGroupCollection = symbolArr.map(
                (symbol) =>
                  ({...(groupCollection[allDB].data?.[symbol] ?? {Symbol: {value: symbol}}), parentGroup: allDB} ??
                  null),
              );
              return [...(acc ?? []), ...fromGroupCollection];
            }
            const parentGroupNotFound = symbolArr.map((symbol) => ({Symbol: {value: symbol}, parentGroup: null}));
            return [...(acc ?? []), ...parentGroupNotFound];
          }
          if (groupCollection?.[key] && Object.keys(groupCollection?.[key] ?? {}).length) {
            if (Object.keys(groupCollection[key]?.data ?? {}).length) {
              const fromGroupCollection = symbolArr
                .map(
                  (symbol) =>
                    ({...(groupCollection[key].data?.[symbol] ?? {Symbol: {value: symbol}}), parentGroup: key} ?? null),
                )
                .filter((data) => data);
              return [...(acc ?? []), ...fromGroupCollection];
            }
            return acc;
          }
          return acc;
        }, []);
      } else if (useParentGroupDataType && (parentGroup || allDB)) {
        let parentKey = allDB && groupCollection?.[allDB] ? allDB : null;
        if (!parentKey && parentGroup && groupCollection?.[parentGroup]) {
          parentKey = parentGroup;
        }
        if (parentKey && Object.keys(groupCollection[parentKey]?.data ?? {}).length) {
          wlData = activeWatchlistSymbols.map((symbol) => {
            if (groupCollection?.[parentKey].data?.[symbol]) {
              return {...groupCollection[parentKey].data?.[symbol], parentGroup: parentKey};
            }
            const findParentGroup = Object.keys(activeWatchlist ?? {}).find((k) => activeWatchlist[k]?.includes(symbol));
            if (findParentGroup && groupCollection?.[findParentGroup]?.data?.[symbol]) {
              return {...groupCollection[findParentGroup].data?.[symbol], parentGroup: findParentGroup };
            }
            return {
              Symbol: {value: symbol},
              parentGroup: parentKey,
            };
          });
        } else {
          wlData = activeWatchlistSymbols.map((symbol) => ({Symbol: {value: symbol}, parentGroup: parentKey}));
        }
      }
      if (isEqual(wlData, watchlistData)) return;
      const highlightKeys = watchlistData?.dataTypes.filter((dt) => dt.type === 'number' && !dt?.hideColumn);
      const diff = differenceWith(wlData, watchlistData?.data, isEqual);
      if (diff?.length && highlightKeys?.length) {
        // highlight the row values that changed
        diff.forEach((row) => {
          const inViewValue = watchlistData?.data.find((r) => r?.Symbol?.value === row?.Symbol?.value);
          if (!inViewValue) return;
          highlightKeys.forEach((k) => {
            const primaryKey = k?.key;
            if (!row[primaryKey] || !inViewValue[primaryKey]?.value || row[primaryKey]?.value === inViewValue[primaryKey]?.value?.value) return;
            const prevValue = Number(inViewValue[primaryKey]?.value);
            const newRowVal = Number(row[primaryKey]?.value);
            highlightRow(primaryKey, prevValue, newRowVal, row?.Symbol?.value);

          });
        })
      }
      setWatchlistData({data: wlData, dataTypes: wlDataTypes ?? []});
    }
  }, [groupCollection, activeWatchlist, activeWatchlistSymbols, allowCustomInputWatchlist]);

  return (
    <div className="scanner-wrap">
      {allowCustomInputWatchlist ? (
        <div>
          <AddToWatchlist
            watchlistData={watchlistData}
            activeWatchlist={activeWatchlist}
            handleAddToWatchlist={handleAddToWatchlist}
          />
        </div>
      ) : null}

      {Object.keys(activeWatchlist ?? {})?.length ? (
        <div className="scanner">
          <WatchlistTable
            tableData={watchlistData?.data ?? []}
            dataTypes={watchlistData?.dataTypes ?? []}
            orderBy={watchlistSettings?.orderBy}
            page={watchlistSettings?.page}
            order={watchlistSettings?.order}
            rowsPerPage={watchlistSettings?.rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeOrderBy={handleChangeOrderBy}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            activeWatchlistSymbols={activeWatchlistSymbols}
            optionsColumns={optionsColumns}
            lockedColumns={lockedColumns}
            optionsAllowed={optionsAllowed}
            allowedChart={allowedChart}
          />
        </div>
      ) : (
        <div className="scanner"> Click the icon next to a Symbol to add to your watchlist. </div>
      )}
    </div>
  );
};
export default React.memo(Watchlist, (prev, next) => {
  const lockedColumnsSame = isEqual(prev?.lockedColumns, next?.lockedColumns);
  const optionsAllowedSame = isEqual(prev?.optionsAllowed, next?.optionsAllowed);
  const optionsColumnsSame = isEqual(prev?.optionsColumns, next?.optionsColumns);
  const displayChartDisabledMessageSame =  isEqual(prev?.displayChartDisabledMessage, next?.displayChartDisabledMessage);
  const allowCustomInputWatchlistSame =isEqual(prev?.allowCustomInputWatchlist, next?.allowCustomInputWatchlist);
  return (
    lockedColumnsSame &&
    optionsAllowedSame &&
    optionsColumnsSame &&
    displayChartDisabledMessageSame &&
    allowCustomInputWatchlistSame
  );
  // return orderBySame && orderSame && pageSame && rowsPerPageSame && dateSame;
});

// export default Watchlist;
