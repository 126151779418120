/* eslint-disable camelcase */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, {useContext} from 'react';
import TableCell from '@mui/material/TableCell';
import {isEqual} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faHeart, faLock} from '@fortawesome/free-solid-svg-icons';
import {faHeart as faHeartRegular} from '@fortawesome/free-regular-svg-icons';
import AppContext from '../../Context/AppContext';
import PSUIcon from '../../Icons/PSU-icon.png';
// import WNMIcon from '../../Icons/WNM-icon-rev.png';

const NumAbbr = require('number-abbreviate');

const getBackgroundColor = (rowValue, dataType) => {
  const {displayOptions, key} = dataType;

  if (!displayOptions || !Object.keys(displayOptions ?? {}).length) return {};
  const {formula} = displayOptions;
  let rowStyle = {};
  const rowVal = rowValue[key]?.value ?? rowValue[key];
  if (formula?.length) {
    formula.forEach((f) => {
      const {operations, style} = f;
      operations.forEach((o) => {
        const {operator, value} = o;
        if (operator === '=' && rowVal === value) {
          rowStyle = {...(rowStyle ?? {}), ...(style ?? {})};
        }
      });
    });
  }
  return rowStyle;
};

const DisplayRowValue = ({
  rowValue,
  dataType,
  overrideKey = null,
  openOptions = null,
  optionsColumns = [],
  optionsAllowed = true,
  openLockedModal = null,
  lockedColumns = [],
  allowedChart = true,
  allowedWatchlist = false,
  groupName = null,
  handleAddToWatchlist,
  handleClickSymbol,
  addedToWatchlist,
  orderBy,
  order,
}) => {
  const {display, key, iconType, type} = dataType;
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (
    (!rowValue ||
      (!rowValue[key]?.value && !rowValue[key]) ||
      (overrideKey && !rowValue[overrideKey]?.value && !rowValue[overrideKey])) &&
    type !== 'pop-out'
  )
    return '';

  const {value, style} = type !== 'pop-out' ? rowValue[key] ?? rowValue[overrideKey] : {value: null, style: null};
  const curVal = value ?? rowValue[key] ?? rowValue[overrideKey];

  if (!curVal && display !== 'joined-column' && type !== 'pop-out' && display !== 'ps-image') return '';

  // const formatPercentage = new Intl.NumberFormat('en-US', {
  //     style: 'percent',
  // });

  const formatNumber = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  });

  const baseClass =
    Object.keys(style ?? {})?.length && Object.keys(style ?? {}).includes('backgroundColor')
      ? `color-item ${Object.keys(style ?? {}).length ? 'inline-styles' : ''}`
      : `no-color-item ${Object.keys(style ?? {}).length ? 'inline-styles' : ''}`;

  if (lockedColumns?.length && lockedColumns?.includes(key)) {
    return (
      <button
        type="button"
        label="open options"
        onClick={() => {
          if (!openLockedModal) return;
          openLockedModal();
        }}
        className="open-options"
      >
        <FontAwesomeIcon icon={faLock} />
      </button>
    );
  }
  switch (display) {
    case 'percentage':
      if (curVal === 'Gift Gap') {
        return (
          <span className={baseClass} style={style ?? {}}>
            {curVal}
          </span>
        );
      }
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`${curVal}%`}
        </span>
      ) : null;
    case 'icon':
      return curVal ? (
        <span className="text-sky-400" style={style ?? {}}>
          <i className={style?.overrideValue ? style?.overrideValue : iconType} />
        </span>
      ) : null;
    case 'custom':
      return curVal ? ( // eslint-disable-next-line
        <span className={baseClass} style={style ?? {}} dangerouslySetInnerHTML={{__html: curVal}} />
      ) : null;
    case 'money-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`$${NumAbbr(Number(curVal), 2)}`.toUpperCase()}
        </span>
      ) : null;
    case 'number-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`${NumAbbr(Number(curVal), 2)}`.toUpperCase()}
        </span>
      ) : null;
    case 'money':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatPrice.format(Number(curVal))}
        </span>
      ) : null;
    case 'number':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatNumber.format(Number(curVal))}
        </span>
      ) : null;
    case 'tags':
      return curVal?.length && Array.isArray(curVal) ? (
        <div className="grid auto-cols-max gap-y-2 gap-x-1 justify-center">
          {curVal.map((tag) => (
            <div className="flex items-center justify-center" key={tag}>
              <span
                className={`${
                  Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
                } relative inline mx-auto items-center justify-center rounded-full border border-gray-300 px-2 py-0.5 hover:bg-slate-300`}
              >
                <div
                  className={`${
                    Object.keys(style ?? {}).length ? '' : 'no-style'
                  } text-sm font-medium text-gray-900 tag-name`}
                  style={style ?? {}}
                >
                  {tag}
                </div>
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <span
            className={`${curVal?.length ? 'border border-gray-300 ' : ''} ${
              Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
            } relative inline mx-auto items-center justify-center rounded-full px-2 py-0.5 hover:bg-slate-300`}
          >
            <div
              className={`${
                Object.keys(style ?? {}).length ? '' : 'no-style'
              } text-sm font-medium text-gray-900 tag-name`}
              style={style ?? {}}
            >
              {curVal}
            </div>
          </span>
        </div>
      );
    case 'bullish-bearish':
      return curVal ? (
        <span
          className={baseClass}
          style={{
            ...(getBackgroundColor(rowValue, dataType) ?? {}),
            ...(style ?? {}),
          }}
        >
          {curVal}
        </span>
      ) : null;

    case 'pop-out': {
      const columnGroup = optionsColumns[key]?.columns?.length ? optionsColumns[key].columns : [];
      const displayOptionsPopout = columnGroup?.length
        ? Object.keys(rowValue ?? {}).some((r) => columnGroup?.includes(r)) && openOptions
        : false;
      return displayOptionsPopout ? (
        !optionsAllowed ? (
          <button
            type="button"
            label="open options"
            onClick={() => {
              if (!openLockedModal) return;
              openLockedModal();
            }}
            className="open-options"
          >
            <FontAwesomeIcon icon={faLock} />
          </button>
        ) : (
          <button
            type="button"
            label="open options"
            onClick={() => {
              openOptions(rowValue, key);
            }}
            className={`open-options ${optionsColumns[key]?.overrideOpenIcon ? 'override-open-icon' : ''}`}
          >
            {optionsColumns[key]?.overrideOpenIcon && optionsColumns[key]?.overrideOpenIcon?.icon ? (
              <img src={optionsColumns[key]?.overrideOpenIcon?.icon} alt="open-options" style={optionsColumns[key]?.overrideOpenIcon?.style ?? {}} className="open-options-icon" />
            ) : (
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            )}
          </button>
        )
      ) : null;
    }
    case 'ps-image': {
      if (curVal) {
        return (
          <span className="ps-icon-wrap">
            <img src={PSUIcon} className="ps-icon" alt="ps-icon" />
          </span>
        );
      }
      return null;
    }
    default:
      return type === 'number' && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatNumber.format(Number(curVal))}
        </span>
      ) : key === 'Symbol' ? (
        allowedWatchlist ? (
          <div className="flex items-center gap-2 justify-center symbol-watchist-wrap" style={{zIndex: 99999999}}>
            <button
              onClick={() => {
                if (!allowedChart) return;
                handleClickSymbol(key, curVal);
              }}
              type="button"
            >
              <span
                style={style ?? {}}
                className={`${baseClass} symbol-item ${
                  allowedChart ? 'cursor-pointer' : ' cursor-default symbol-item-no-link'
                } `}
              >
                {curVal}
              </span>
            </button>
            <button
              onClick={() => {
                if (!allowedWatchlist) return;
                handleAddToWatchlist(groupName, curVal, !addedToWatchlist);
              }}
              type="button"
            >
              <FontAwesomeIcon
                className={`${addedToWatchlist ? 'added' : ''} watchlist-item`}
                icon={addedToWatchlist ? faHeart : faHeartRegular}
              />
            </button>
          </div>
        ) : (
          <button
            type="button"
            style={style ?? {}}
            onClick={() => {
              if (!allowedChart) return;
              handleClickSymbol(key, curVal);
            }}
            className={`${baseClass} symbol-item ${
              allowedChart ? 'cursor-pointer' : ' cursor-default symbol-item-no-link'
            } `}
          >
            {/* {curVal?.charAt(0) === 'e' ? curVal.substring(1) : curVal} */}
            {curVal}
          </button>
        )
      ) : (
        <span style={style ?? {}} className={`${baseClass + (key === 'Symbol' ? 'symbol-item' : '')}`}>
          {typeof curVal === 'string' && curVal?.includes('A+ Setup') ? 'A+' : curVal}
        </span>
      );
  }
};

const MemoizedDisplayRowValue = React.memo(DisplayRowValue, (prev, next) => {
  const rowValueSame = isEqual(prev?.rowValue?.[prev?.dataType], next?.rowValue?.[next?.dataType]);
  const dataTypeSame = isEqual(prev?.dataType, next?.dataType);
  const overrideKeySame = prev?.overrideKey === next?.overrideKey;
  const openOptionsSame = prev?.openOptions === next?.openOptions;
  const optionsColumnsSame = isEqual(prev?.optionsColumns, next?.optionsColumns);
  const optionsAllowedSame = prev?.optionsAllowed === next?.optionsAllowed;
  const openLockedModalSame = prev?.openLockedModal === next?.openLockedModal;
  const lockedColumnsSame = isEqual(prev?.lockedColumns, next?.lockedColumns);
  const allowedChartSame = prev?.allowedChart === next?.allowedChart;
  const allowedWatchlistSame = prev?.allowedWatchlist === next?.allowedWatchlist;
  const groupNameSame = prev?.groupName === next?.groupName;
  const addedToWatchlistSame = prev?.addedToWatchlist === next?.addedToWatchlist;
  const orderSame = prev?.order === next?.order;
  const orderBySame = prev?.orderBy === next?.orderBy;
  return (
    rowValueSame &&
    dataTypeSame &&
    overrideKeySame &&
    openOptionsSame &&
    optionsColumnsSame &&
    optionsAllowedSame &&
    openLockedModalSame &&
    lockedColumnsSame &&
    allowedChartSame &&
    allowedWatchlistSame &&
    groupNameSame &&
    addedToWatchlistSame &&
    orderSame &&
    orderBySame
  );
});

const ScannerCell = ({
  rowValue,
  dataType,
  optionsColumns,
  row,
  index,
  optionsAllowed,
  lockedColumns,
  allowedChart,
  allowedWatchlist,
  groupName,
  addedToWatchlist,
  orderBy,
  order,
}) => {
  const {getSymbolData, openOptionsModal, openLockedModal, handleAddToWatchlist} = useContext(AppContext);
  const openOptions = (r, key) => {
    if (!openOptionsModal) return;
    openOptionsModal(r, groupName, key);
  };
  const handleClickSymbol = (key = null, symbol = null) => {
    if (symbol && key === 'Symbol' && allowedChart) {
      // if (displayChartDisabledMessage) {
      //   // eslint-disable-next-line no-alert
      //   alert(
      //     'Charts are offline to help increase SAM speed. We will have these up and running soon. Thanks for your patience!',
      //   );
      //   return;
      // }
      getSymbolData(symbol, groupName);
    }
  };
  return (
    <TableCell
      key={`${row ? row._id?.value : ''}-${dataType.key}-${row?.Symbol?.value}-${dataType.type}-${index * 2}`}
      align="center"
      className={`${dataType.convertTo === 'time' ? 'whitespace-nowrap' : 'whitespace-nowrap'} ${dataType.key} ${
        row?.Symbol?.value
      }`}
    >
      <span className="scanner-item">
        {dataType?.display === 'joined-column' && rowValue?.[dataType?.key]?.displayedRowValue?.length ? (
          <span style={{display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center'}}>
            {rowValue[dataType.key].displayedRowValue.map((val, i) => {
              if (!val?.dataType || !val?.overrideKey) {
                return null;
              }
              return (
                <span key={`${val.dataType?.key}-${val.overrideKey}`}>
                  <MemoizedDisplayRowValue
                    rowValue={rowValue}
                    dataType={val?.dataType}
                    overrideKey={val?.overrideKey}
                    openOptions={openOptions}
                    optionsColumns={optionsColumns}
                    optionsAllowed={optionsAllowed}
                    openLockedModal={openLockedModal}
                    lockedColumns={lockedColumns}
                    allowedChart={allowedChart}
                    allowedWatchlist={allowedWatchlist}
                    groupName={groupName}
                    handleAddToWatchlist={handleAddToWatchlist}
                    handleClickSymbol={handleClickSymbol}
                    addedToWatchlist={addedToWatchlist}
                    orderBy={orderBy}
                    order={order}
                  />
                </span>
              );
            })}
          </span>
        ) : (
          <MemoizedDisplayRowValue
            rowValue={rowValue}
            dataType={dataType}
            overrideKey={null}
            openOptions={openOptions}
            optionsColumns={optionsColumns}
            optionsAllowed={optionsAllowed}
            openLockedModal={openLockedModal}
            lockedColumns={lockedColumns}
            allowedChart={allowedChart}
            allowedWatchlist={allowedWatchlist}
            groupName={groupName}
            handleAddToWatchlist={handleAddToWatchlist}
            handleClickSymbol={handleClickSymbol}
            addedToWatchlist={addedToWatchlist}
            orderBy={orderBy}
            order={order}
          />
        )}
      </span>
    </TableCell>
  );
};

const MemoizedScannerCell = React.memo(ScannerCell, (prev, next) => { 
  //   const prevNextSame = isEqual(prev?.row, next?.row);
  let rowValueSame = isEqual(prev?.rowValue?.[prev?.dataType?.key], next?.rowValue?.[next?.dataType?.key]);
  if (prev?.dataType?.type === 'pop-out' && next?.dataType?.type === 'pop-out') {
    const prevPopoutValuesSame = Object.keys(prev?.rowValue ?? {}).some((r) =>
      [...prev?.optionsColumns[prev?.dataType?.key]?.columns ?? []]?.includes(r),
    );
    const nextPopoutValuesSame = Object.keys(next?.rowValue ?? {}).some((r) =>
      [...next?.optionsColumns[next?.dataType?.key]?.columns ?? []].includes(r),
    );
    rowValueSame = prevPopoutValuesSame === nextPopoutValuesSame;
  }
  const dataTypeSame = isEqual(prev?.dataType, next?.dataType);
  const optionsColumnsSame = isEqual(prev?.optionsColumns, next?.optionsColumns);
  const optionsAllowedSame = prev?.optionsAllowed === next?.optionsAllowed;
  const lockedColumnsSame = isEqual(prev?.lockedColumns, next?.lockedColumns);
  const allowedChartSame = prev?.allowedChart === next?.allowedChart;
  const allowedWatchlistSame = prev?.allowedWatchlist === next?.allowedWatchlist;
  const groupNameSame = prev?.groupName === next?.groupName;
  const activeWatchlistSame = isEqual(prev?.activeWatchlist, next?.activeWatchlist);
  const addedToWatchlistSame = prev?.addedToWatchlist === next?.addedToWatchlist;
  const orderSame = prev?.order === next?.order;
  const orderBySame = prev?.orderBy === next?.orderBy;

  return (
    rowValueSame &&
    dataTypeSame &&
    optionsColumnsSame &&
    optionsAllowedSame &&
    lockedColumnsSame &&
    allowedChartSame &&
    allowedWatchlistSame &&
    groupNameSame &&
    activeWatchlistSame &&
    addedToWatchlistSame &&
    orderSame &&
    orderBySame
  );
});

export default MemoizedScannerCell;
